import React, { useState } from 'react';
import './LeadgenForm.css'; // Assuming this is the path to your CSS file

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Thanks, ${name}! We will be in touch soon!`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  }

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <input 
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          onKeyDown={handleKeyPress}
        />
        <input 
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          onKeyDown={handleKeyPress}
        />
        <input 
          type="tel"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          placeholder="Mobile"
          onKeyDown={handleKeyPress}
        />
        <textarea 
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Message"
          onKeyDown={handleKeyPress}
        />
        <input type="submit" value="Submit" className='submitButton'/>
      </form>
    </div>
  );
};

export default Form;
