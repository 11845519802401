import React from "react";
import './App.css'
import Homepage from "./Hompage";

const App = () => {

    return (
        <Homepage />
    );
};

export default App;