import React from "react";
import LeadgenForm from './LeadgenForm'
import logo from '../assets/logo.jpg';
import growth from '../assets/growth.png';

const Homepage = () => {
    
    function handleClick() {
        const element = document.getElementById('form');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    
    return (
   <div>

    {/* Top Container */}
    <header>
    <div className="container">
        <img src={ logo } alt="SMPL Logo" className="fadeIn logotop"></img>
        <h1 id="splashtextlarge" className="fadeIn">SMPL Limited.</h1>
        <button className="rounded-button fadeIn" onClick={handleClick}>Contact Us</button>
    </div>
    </header>
   
    {/* Next Level Copy Container */}

    <section>
    <div className="container white-bg">
    <h1 className="pad-top gradient-text gradient-text">Take Your Business to the Next Level</h1>
    <img src= { growth } alt="a desk in an office"></img>
    <h2 className="text-columnar pad-bottom gradient-text">Whether you're a startup seeking to establish an online presence or an established corporation, we craft bespoke digital solutions that elevate your brand, enhance
    your customer experience and optimize your sales. Partner with us today and experience the transformative power of bespoke development: made SMPL.</h2>
    </div>

    </section>

    {/* Our Services Section */}
    <section>
    <div className="container">
        <h1 className="align-left white-text">Our Services</h1>
        <h2 className="align-left gradient-text2">Experts in Web and Mobile app development, Design, Digital Marketing,</h2>
        <h2 className="pad-bottom align-left gradient-text2">Automation, Data and AI integration</h2>
        <div class="card-container">
  <div class="card">
    <h3 class="card-title gradient-text">Websites</h3>
    <p class="card-text white-text">Engage audiences with dynamic content, stunning visuals, and interactive features. <span className="gradient-text">Make your mark</span> online, one click at a time.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Web Apps</h3>
    <p class="card-text white-text">Harness the potential of customized, accessible-anywhere Apps that streamline operations and <span className="gradient-text">boost engagement</span>.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Mobile Apps</h3>
    <p class="card-text white-text">Experience <span className="gradient-text">seamless functionality</span>, optimized performance, and personalized features right at your fingertips.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Design Services</h3>
    <p class="card-text white-text">Experience transformative, aesthetic designs that speak your message, <span className="gradient-text">captivate audiences</span>, and elevate your image.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Social Media Management</h3>
    <p class="card-text white-text">Harness the power of social platforms to engage, <span className="gradient-text">grow</span>, and convert your audience into brand ambassadors.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Ad Campaigns</h3>
    <p class="card-text white-text">Leverage compelling narratives and eye-catching visuals to <span className="gradient-text">connect</span>, captivate, and convert your target audience.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Automation</h3>
    <p class="card-text white-text">Embrace the power of automation to streamline processes, reduce errors, and <span className="gradient-text">free up time</span> for strategic tasks.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">Data Analysis</h3>
    <p class="card-text white-text">Dive into the power of data, decipher patterns, trends, and make informed decisions that <span className="gradient-text">drive growth</span> and success.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">API Development</h3>
    <p class="card-text white-text">Leverage our API Development services to bridge software applications, <span className="gradient-text">optimise functionality</span>, and enrich user experiences.</p>
  </div>
  <div class="card">
    <h3 class="card-title gradient-text">AI Solutions</h3>
    <p class="card-text white-text">Embrace artificial intelligence to <span className="gradient-text">revolutionise processes</span>, enhance decision-making, and deliver personalized experiences like never before.</p>
  </div>
</div>
    </div>
    </section>

     {/* Contact form Section */}
    <section>
    <div className="container white-bg">
        <h1 className="fadeIn pad-top" id="form">Want to know <span className="gradient-text">more</span>?</h1>
        <LeadgenForm />
        <div className="spacer"></div>
    </div>
    </section>

    {/* Footer Section */}

    <footer>
    <div className="container">
        <div className="footer-content fadeIn">
            <h3 className="footer-heading gradient-text">Connect with us</h3>
            <p className="footer-text gradient-text2">
                Let's continue the conversation. Feel free to reach out to us on our social media platforms or send us an email.
            </p>
            <hr className="hr-white"></hr>
            <div className="footer-links">
                <div className="footer-column">
                    <h4>Company</h4>
                    <a href="google.com" className="footer-link">About Us</a>
                    <a href="google.com" className="footer-link">Privacy Policy</a>
                    <a href="google.com" className="footer-link">Cookie Policy</a>
                </div>
                {/* <div className="footer-column">
                    <h4>Resources</h4>
                    <a href="google.com" className="footer-link">Blog</a>
                    <a href="google.com" className="footer-link">FAQs</a>
                    <a href="google.com" className="footer-link">Support</a>
                    <a href="google.com" className="footer-link">Site Map</a>
                </div> */}
                <div className="footer-column">
                    <h4>Social</h4>
                    <a href="https://www.instagram.com/smpllimited/" className="footer-link">Instagram</a>
                    <a href="https://twitter.com/SMPLLimited" className="footer-link">Twitter</a>
                    <a href="https://www.linkedin.com/company/smpl-ltd/" className="footer-link">LinkedIn</a>
                </div>
            </div>
        </div>
        <div className="copyright fadeIn">
        <hr className="hr-white"></hr>
            <p className="copyright-text gradient-text">&copy; 2023 SMPL Ltd. All rights reserved.</p>
        </div>
    </div>
    </footer>


    </div>
    )};

export default Homepage;